import { gql, useMutation } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

import useBrands from 'pared/layouts/hooks/useBrands'

import { IQueryDataType } from './useListLocationGroupMapping'

export interface IOptionType {
  manageType: 'editLocationGroup'
  locationGroupId: number
  locationGroupType: string
  locationGroupName: string
  locationIds: number[]
}

export interface IDataType {
  locationGroupType: string
  value: string
  onChange: (value: IDataType['value']) => void
  loading: boolean
  errorMessage?: string
  submit: () => Promise<void>
  deletable: boolean
  delete: () => Promise<void>
}

const updateMutate = gql`
  mutation UpdateLocationGroup($locationGroupId: Int!, $name: String!) {
    updateLocationGroup(
      input: { id: $locationGroupId, patch: { name: $name } }
    ) {
      clientMutationId
    }
  }
`

const deleteMutate = gql`
  mutation DeleteLocationGroup($locationGroupId: Int!) {
    deleteLocationGroup(input: { id: $locationGroupId }) {
      clientMutationId
    }
  }
`

const useEditLocationGroup = (
  data: IQueryDataType | null,
  option: IOptionType | null,
  close: () => void,
) => {
  const editLocationGroupHook = useState<string>('')
  const isSubmittingHook = useState(false)
  const prevManageType = usePrevious(option?.manageType)
  const { brand } = useBrands()
  const [updateLocationGroup, { loading: updateLoading }] = useMutation(
    updateMutate,
    {
      refetchQueries: ['listLocationGroupMapping'],
      awaitRefetchQueries: true,
    },
  )
  const [deleteLocationGroup, { loading: deleteLoading }] = useMutation(
    deleteMutate,
    {
      refetchQueries: ['listLocationGroupMapping'],
      awaitRefetchQueries: true,
    },
  )
  const loading = updateLoading || deleteLoading
  const options = useMemo(
    () =>
      !option
        ? []
        : (data?.listLocationGroupMapping.nodes || [])
            .filter((n) => n.locationGroupType === option.locationGroupType)
            .reduce(
              (result, n) =>
                result.some((r) => r === n.locationGroupName)
                  ? result
                  : [...result, n.locationGroupName],
              [] as string[],
            ),
    [data, option],
  )

  useEffect(() => {
    if (prevManageType !== option?.manageType) {
      if (option?.manageType === 'editLocationGroup')
        editLocationGroupHook[1](option.locationGroupName)
      else {
        editLocationGroupHook[1]('')
        isSubmittingHook[1](false)
      }
    }
  }, [option, prevManageType, editLocationGroupHook, isSubmittingHook])

  return useMemo((): IDataType | undefined => {
    if (option?.manageType !== 'editLocationGroup') return

    const [value, onChange] = editLocationGroupHook
    const [isSubmitting, setIsSubmitting] = isSubmittingHook
    const validator = (isSubmitting: boolean) => {
      if (isSubmitting) {
        if (!value) return `Couldn't be empty`

        if (value === option.locationGroupName)
          return `Couldn't use the original name`
      }

      if (value !== option.locationGroupName && options.includes(value))
        return `${value} exists`
    }

    return {
      locationGroupType: option.locationGroupType,
      value,
      onChange,
      loading,
      errorMessage: validator(isSubmitting),
      submit: async () => {
        const errorMessage = validator(true)
        const hasError = Boolean(errorMessage)

        if (!isSubmitting) setIsSubmitting(true)

        if (loading || hasError) return

        await updateLocationGroup({
          variables: {
            locationGroupId: option.locationGroupId,
            name: value,
          },
        })
        setIsSubmitting(false)
        close()
      },
      deletable: option.locationIds.length === 0,
      delete: async () => {
        await deleteLocationGroup({
          variables: {
            locationGroupId: option.locationGroupId,
          },
        })
        close()
      },
    }
  }, [
    option,
    close,
    editLocationGroupHook,
    isSubmittingHook,
    loading,
    brand,
    options,
  ])
}

export default useEditLocationGroup
