import FormControl from '@material-ui/core/FormControl'
import TextField from '@mui/material/TextField'
import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { useVariables } from '../variables'

export interface IPropsType {
  type: 'input'
  value: string
  onChange: string
  errorMessage?: string
  label?: string
  placeholder?: string
  width?: string
  margin?: string
}

interface IInputType {
  value: string
  onChange: (value: IInputType['value']) => void
  errorMessage?: string
}

const StyledFormControl = styled(FormControl)<{ styleMargin?: string }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 25px;
  ${({ styleMargin }) => (!styleMargin ? '' : `margin: ${styleMargin};`)}
`

const Label = styled.div`
  display: inline-block;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const StyledTextField = styled(TextField)<{ width: string | undefined }>`
  min-width: 150px;
  width: ${({ width }) => width || '150px'};

  .MuiInputBase-root {
    font-family: Lexend-Regular;
    border-radius: 0;
    cursor: default;

    input {
      cursor: default;
    }
  }
`

const Input = ({ label, placeholder, width, margin, ...props }: IPropsType) => {
  const { variables, template } = useVariables()
  const { value, onChange, errorMessage } = useMemo(
    (): IInputType => ({
      value: _.get(variables, props.value),
      onChange: _.get(variables, props.onChange),
      errorMessage: props.errorMessage && _.get(variables, props.errorMessage),
    }),
    [props, variables],
  )

  return (
    <StyledFormControl styleMargin={margin} variant="outlined" margin="dense">
      {!label ? null : <Label>{template(label)}</Label>}

      <StyledTextField
        {...(!errorMessage
          ? {}
          : {
              error: true,
              helperText: errorMessage,
            })}
        value={value}
        placeholder={placeholder || ''}
        onChange={(e) => onChange(e.target.value)}
        width={width}
        variant="outlined"
        size="small"
      />
    </StyledFormControl>
  )
}

export default React.memo(Input) as unknown as typeof Input
