import { IConfigsType } from '../../types'

const fwWingstopFlashSalesAndTicketCompReport: IConfigsType = {
  'fw_wingstop-customized-reports-7375b6fb': {
    '/:brand/sales_and_ticket_comp': {
      variables: ['corporateGroup'],
      groupFilter: {
        api: 'fwWingstopCorporateFilter',
        defaultValue: ['Market', 'Market Breakdown'],
      },
      dateFilter: {
        types: ['yesterday', 'custom'],
        defaultType: 'yesterday',
        isDailyCustom: true,
      },
      reportTitle: {
        type: 'title',
        title: 'Sales and Ticket Comp Report',
      },
      downloadSalesReport: {
        type: 'download-csv',
        api: 'farwestCustomizedFlash',
        fileName: 'getexpo-far-west-sales-and-ticket-comp-report',
        fileNameFn: (date: string) =>
          `getexpo-far-west-sales-and-ticket-comp-report-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'ro',
            title: 'RO',
            hide: '<%- corporateGroup.tableColumns.length < 2  %>',
          },
          {
            key: 'market',
            title: 'Market',
            hide: '<%- corporateGroup.tableColumns.length < 2  %>',
          },
          {
            key: 'openAt',
            title: 'Open Day',
            hide: '<%- corporateGroup.tableColumns.length < 2  %>',
          },
          {
            key: 'netSales',
            title: 'Sales',
          },
          {
            key: 'netSalesSameDayLastYear',
            title: 'Sales same day Last Year',
          },
          {
            key: 'netSalesSameDay2YearsAgo',
            title: 'Sales same day Last 2 Years',
          },
          {
            key: 'netSalesWtd',
            title: 'Sales WTD',
          },
          {
            key: 'netSalesWtdLastWeek',
            title: 'Sales WTD Prior Week',
          },
          {
            key: 'comparableNetSalesWtdLy',
            title: 'Sales WTD Last Year',
          },
          {
            key: 'averageNetSalesTrailing7Days',
            title: 'Sales Avg T7',
          },
          {
            key: 'averageNetSalesTrailing7DaysLastYear',
            title: 'Sales Avg T7 Last Year',
          },
          {
            key: 'netSales7DaysAgo',
            title: 'Sales Prior Week',
          },
          {
            key: 'netSalesPtd',
            title: 'Sales PTD',
          },
          {
            key: 'netSalesQtd',
            title: 'Sales QTD',
          },
          {
            key: 'netSalesYtd',
            title: 'Sales YTD',
          },
          {
            key: 'netSalesTtm',
            title: 'Sales TTM',
          },
          {
            key: 'netSalesPercentageDiffToLastYear',
            title: 'Comp Sales Daily (1-yr SSS)',
          },
          {
            key: 'netSalesPercentageDiffTo7DaysAgo',
            title: 'Comp Sales WoW (1-yr WoW SSS)',
          },
          {
            key: 'averageNetSalesTrailing7DaysPercentageDiffToLastYear',
            title: 'Comp Sales T7 (1-yr T7 SSS)',
          },
          {
            key: 'netSalesLastWeekPercentageDiffToLastYear',
            title: 'Comp Sales P-Wk (1-yr P-Wk SSS)',
          },
          {
            key: 'netSalesWtdPercentageDiffToLastYear',
            title: 'Comp Sales WTD (1-yr WTD SSS)',
          },
          {
            key: 'netSalesPtdPercentageDiffToLastYear',
            title: 'Comp Sales PTD (1-yr PTD SSS)',
          },
          {
            key: 'netSalesTrailing30DaysPercentageDiffToLastYear',
            title: 'Comp Sales T30 (1-yr T30 SSS)',
          },
          {
            key: 'netSalesQtdPercentageDiffToLastYear',
            title: 'Comp Sales QTD (1-yr QTD SSS)',
          },
          {
            key: 'netSalesYtdPercentageDiffToLastYear',
            title: 'Comp Sales YTD (1-yr YTD SSS)',
          },
          {
            key: 'netSalesPercentageDiffTo2YearsAgo',
            title: 'Comp Sales Daily (2-yr SSS)',
          },
          {
            key: 'netSalesWtdPercentageDiffTo2YearsAgo',
            title: 'Comp Sales WTD (2-yr WTD SSS)',
          },
          {
            key: 'netSalesTrailing7DaysPercentageDiffTo2YearsAgo',
            title: 'Comp Sales T7 (2-yr T7 SSS)',
          },
          {
            key: 'netSalesLastWeekPercentageDiffTo2YearsAgo',
            title: 'Comp Sales P-Wk (2-yr P-Wk SSS)',
          },
          {
            key: 'netSalesPtdPercentageDiffTo2YearsAgo',
            title: 'Comp Sales PTD (2-yr PTD SSS)',
          },
          {
            key: 'netSalesQtdPercentageDiffTo2YearsAgo',
            title: 'Comp Sales QTD (2-yr QTD SSS)',
          },
          {
            key: 'netSalesYtdPercentageDiffTo2YearsAgo',
            title: 'Comp Sales YTD (2-yr YTD SSS)',
          },
          {
            key: 'checkCount',
            title: 'Tickets',
          },
          {
            key: 'checkCount7DaysAgo',
            title: 'Tickets Prior Week',
          },
          {
            key: 'checkCountWtd',
            title: 'Tickets WTD',
          },
          {
            key: 'checkCountPtd',
            title: 'Tickets PTD',
          },
          {
            key: 'checkCountQtd',
            title: 'Tickets QTD',
          },
          {
            key: 'checkCountSameDayLastYear',
            title: 'Tickets Prior Year',
          },
          {
            key: 'checkCountSameDay2YearsAgo',
            title: 'Tickets Prior 2 Years',
          },
          {
            key: 'checkCountPercentageDiffToLastYear',
            title: 'Comp Tickets Daily (1-yr SST)',
          },
          {
            key: 'checkCountPercentageDiffTo7DaysAgo',
            title: 'Comp Tickets WoW (1-yr WoW SST)',
          },
          {
            key: 'averageCheckCountTrailing7DaysPercentageDiffToLastYear',
            title: 'Comp Tickets T7 (1-yr T7 SST)',
          },
          {
            key: 'checkCountLastWeekPercentageDiffToLastYear',
            title: 'Comp Tickets P-Wk (1-yr P-Wk SST)',
          },
          {
            key: 'checkCountWtdPercentageDiffToLastYear',
            title: 'Comp Tickets WTD (1-yr WTD SST)',
          },
          {
            key: 'checkCountPtdPercentageDiffToLastYear',
            title: 'Comp Tickets PTD (1-yr PTD SST)',
          },
          {
            key: 'checkCountTrailing30DaysPercentageDiffToLastYear',
            title: 'Comp Tickets T30 (1-yr T30 SST)',
          },
          {
            key: 'checkCountQtdPercentageDiffToLastYear',
            title: 'Comp Tickets QTD (1-yr QTD SST)',
          },
          {
            key: 'checkCountYtdPercentageDiffToLastYear',
            title: 'Comp Tickets YTD (1-yr YTD SST)',
          },
          {
            key: 'checkCountPercentageDiffTo2YearsAgo',
            title: 'Comp Tickets Daily (2-yr SST)',
          },
          {
            key: 'checkCountWtdPercentageDiffTo2YearsAgo',
            title: 'Comp Tickets WTD (2-yr WTD SST)',
          },
          {
            key: 'checkCountTrailing7DaysPercentageDiffTo2YearsAgo',
            title: 'Comp Tickets T7 (2-yr T7 SST)',
          },
          {
            key: 'checkCountLastWeekPercentageDiffTo2YearsAgo',
            title: 'Comp Tickets P-Wk (2-yr P-Wk SST)',
          },
          {
            key: 'checkCountPtdPercentageDiffTo2YearsAgo',
            title: 'Comp Tickets PTD (2-yr PTD SST)',
          },
          {
            key: 'checkCountQtdPercentageDiffTo2YearsAgo',
            title: 'Comp Tickets QTD (2-yr QTD SST)',
          },
          {
            key: 'checkCountYtdPercentageDiffTo2YearsAgo',
            title: 'Comp Tickets YTD (2-yr YTD SST)',
          },
          {
            key: 'checkAverage',
            title: 'Checks',
          },
          {
            key: 'checkAverageLastWeek',
            title: 'Checks Prior Week',
          },
          {
            key: 'checkAveragePtd',
            title: 'Checks PTD',
          },
          {
            key: 'checkAverageQtd',
            title: 'Checks QTD',
          },
          {
            key: 'checkAverageSameDayLastYear',
            title: 'Checks Prior Year',
          },
          {
            key: 'checkAverageSameDay2YearsAgo',
            title: 'Checks Prior 2 Years',
          },
          {
            key: 'checkAveragePercentageDiffToLastYear',
            title: 'Comp Checks Daily (1-yr SSC)',
          },
          {
            key: 'checkAveragePercentageDiffTo7DaysAgo',
            title: 'Comp Checks WoW (1-yr WoW SSC)',
          },
          {
            key: 'checkAverageTrailing7DaysPercentageDiffToLastYear',
            title: 'Comp Checks T7 (1-yr T7 SSC)',
          },
          {
            key: 'checkAverageLastWeekPercentageDiffToLastYear',
            title: 'Comp Checks P-Wk (1-yr P-Wk SSC)',
          },
          {
            key: 'checkAverageWtdPercentageDiffToLastYear',
            title: 'Comp Checks WTD (1-yr WTD SSC)',
          },
          {
            key: 'checkAveragePtdPercentageDiffToLastYear',
            title: 'Comp Checks PTD (1-yr PTD SSC)',
          },
          {
            key: 'checkAverageTrailing30DaysPercentageDiffToLastYear',
            title: 'Comp Checks T30 (1-yr T30 SSC)',
          },
          {
            key: 'checkAverageQtdPercentageDiffToLastYear',
            title: 'Comp Checks QTD (1-yr QTD SSC)',
          },
          {
            key: 'checkAverageYtdPercentageDiffToLastYear',
            title: 'Comp Checks YTD (1-yr YTD SSC)',
          },
          {
            key: 'checkAveragePercentageDiffTo2YearsAgo',
            title: 'Comp Checks Daily (2-yr SSC)',
          },
          {
            key: 'checkAverageWtdPercentageDiffTo2YearsAgo',
            title: 'Comp Checks WTD (2-yr WTD SSC)',
          },
          {
            key: 'checkAverageTrailing7DaysPercentageDiffTo2YearsAgo',
            title: 'Comp Checks T7 (2-yr T7 SSC)',
          },
          {
            key: 'checkAverageLastWeekPercentageDiffTo2YearsAgo',
            title: 'Comp Checks P-Wk (2-yr P-Wk SSC)',
          },
          {
            key: 'checkAveragePtdPercentageDiffTo2YearsAgo',
            title: 'Comp Checks PTD (2-yr PTD SSC)',
          },
          {
            key: 'checkAverageQtdPercentageDiffTo2YearsAgo',
            title: 'Comp Checks QTD (2-yr QTD SSC)',
          },
          {
            key: 'checkAverageYtdPercentageDiffTo2YearsAgo',
            title: 'Comp Checks YTD (2-yr YTD SSC)',
          },
        ],
      },
      salesReportTableSales: {
        type: 'table',
        api: 'farwestCustomizedFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          {
            key: 'ro',
            title: 'RO',
            width: '150px',
            sortable: true,
            hide: '<%- corporateGroup.tableColumns.length < 2  %>',
          },
          {
            key: 'market',
            title: 'Market',
            highlight: true,
            sortable: true,
            hide: '<%- corporateGroup.tableColumns.length < 2 %>',
          },
          {
            key: 'openAt',
            title: 'Open Day',
            width: '100px',
            sortable: true,
            hide: '<%- corporateGroup.tableColumns.length < 2  %>',
          },
          {
            key: 'sales',
            title: 'Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'netSales',
                title: 'Sales',
                sortable: true,
              },
              {
                key: 'netSalesSameDayLastYear',
                title: 'Sales same day Last Year',
                sortable: true,
              },
              {
                key: 'averageNetSalesTrailing7Days',
                title: 'Sales Avg T7',
                sortable: true,
              },
              {
                key: 'averageNetSalesTrailing7DaysLastYear',
                title: 'Sales Avg T7 Last Year',
                sortable: true,
              },
              {
                key: 'netSalesWtd',
                title: 'Sales WTD ',
                sortable: true,
              },
              {
                key: 'netSalesWtdLastWeek',
                title: 'Sales WTD Prior Week',
                sortable: true,
              },
              {
                key: 'netSalesTtm',
                title: 'Sales TTM',
                sortable: true,
              },
              {
                key: 'netSalesPercentageDiffTo7DaysAgo',
                title: 'WoW',
                sortable: true,
              },
            ],
          },
          {
            key: 'compSales',
            title: 'Comp Sales',
            align: 'center',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYear',
                title: 'Daily (1-yr)',
                sortable: true,
              },
              {
                key: 'averageNetSalesTrailing7DaysPercentageDiffToLastYear',
                title: 'T7',
                sortable: true,
              },
              {
                key: 'netSalesLastWeekPercentageDiffToLastYear',
                title: 'P-Wk',
                sortable: true,
              },
              {
                key: 'netSalesWtdPercentageDiffToLastYear',
                title: 'WTD',
                sortable: true,
              },
              {
                key: 'netSalesPtdPercentageDiffToLastYear',
                title: 'PTD',
                sortable: true,
              },
              {
                key: 'netSalesQtdPercentageDiffToLastYear',
                title: 'QTD',
                sortable: true,
              },
              {
                key: 'netSalesYtdPercentageDiffToLastYear',
                title: 'YTD',
                sortable: true,
              },
            ],
          },
        ],
      },
      salesReportTableTickets: {
        type: 'table',
        api: 'farwestCustomizedFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          {
            key: 'ro',
            title: 'RO',
            width: '150px',
            sortable: true,
            hide: '<%- corporateGroup.tableColumns.length < 2  %>',
          },
          {
            key: 'market',
            title: 'Market',
            highlight: true,
            sortable: true,
            hide: '<%- corporateGroup.tableColumns.length < 2 %>',
          },
          {
            key: 'openAt',
            title: 'Open Day',
            width: '100px',
            sortable: true,
            hide: '<%- corporateGroup.tableColumns.length < 2  %>',
          },
          {
            key: 'tickets',
            title: 'Tickets',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'checkCount',
                title: 'Tickets',
                sortable: true,
              },
              {
                key: 'checkCount7DaysAgo',
                title: 'Tickets Prior Week',
                width: '80px',
                sortable: true,
                align: 'center',
              },
              {
                key: 'checkCountSameDayLastYear',
                title: 'Tickets Prior Year',
                width: '80px',
                sortable: true,
                align: 'center',
              },
              {
                key: 'checkCountPercentageDiffTo7DaysAgo',
                title: 'WoW',
                sortable: true,
              },
            ],
          },
          {
            key: 'compTickets',
            title: 'Comp Tickets',
            align: 'center',
            children: [
              {
                key: 'checkCountPercentageDiffToLastYear',
                title: 'Daily (1-yr)',
                sortable: true,
                align: 'center',
              },
              {
                key: 'checkCountLastWeekPercentageDiffToLastYear',
                title: 'P-Wk',
                sortable: true,
              },
              {
                key: 'checkCountWtdPercentageDiffToLastYear',
                title: 'WTD',
                sortable: true,
              },
              {
                key: 'checkCountPtdPercentageDiffToLastYear',
                title: 'PTD',
                sortable: true,
              },
              {
                key: 'checkCountQtdPercentageDiffToLastYear',
                title: 'QTD',
                sortable: true,
              },
              {
                key: 'checkCountYtdPercentageDiffToLastYear',
                title: 'YTD',
                sortable: true,
              },
            ],
          },
        ],
      },
      salesReportTableChecks: {
        type: 'table',
        api: 'farwestCustomizedFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          {
            key: 'ro',
            title: 'RO',
            width: '150px',
            sortable: true,
            hide: '<%- corporateGroup.tableColumns.length < 2  %>',
          },
          {
            key: 'market',
            title: 'Market',
            highlight: true,
            sortable: true,
            hide: '<%- corporateGroup.tableColumns.length < 2 %>',
          },
          {
            key: 'openAt',
            title: 'Open Day',
            width: '100px',
            sortable: true,
            hide: '<%- corporateGroup.tableColumns.length < 2  %>',
          },
          {
            key: 'checks',
            title: 'Checks',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'checkAverage',
                title: 'Checks',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'checkAverageLastWeek',
                title: 'Checks Prior Week',
                width: '80px',
                decimal: 2,
                sortable: true,
                align: 'center',
              },
              {
                key: 'checkAverageSameDayLastYear',
                title: 'Checks Prior Year',
                width: '80px',
                decimal: 2,
                sortable: true,
                align: 'center',
              },
              {
                key: 'checkAveragePercentageDiffTo7DaysAgo',
                title: 'WoW',
                sortable: true,
              },
            ],
          },
          {
            key: 'compChecks',
            title: 'Comp Checks',
            align: 'center',
            children: [
              {
                key: 'checkAveragePercentageDiffToLastYear',
                title: 'Daily (1-yr)',
                sortable: true,
                align: 'center',
              },
              {
                key: 'checkAverageLastWeekPercentageDiffToLastYear',
                title: 'P-Wk',
                sortable: true,
              },
              {
                key: 'checkAverageWtdPercentageDiffToLastYear',
                title: 'WTD',
                sortable: true,
              },
              {
                key: 'checkAveragePtdPercentageDiffToLastYear',
                title: 'PTD',
                sortable: true,
              },
              {
                key: 'checkAverageQtdPercentageDiffToLastYear',
                title: 'QTD',
                sortable: true,
              },
              {
                key: 'checkAverageYtdPercentageDiffToLastYear',
                title: 'YTD',
                sortable: true,
              },
            ],
          },
        ],
      },
    },
  },
}

export default fwWingstopFlashSalesAndTicketCompReport
