import buildPnl from './buildPnl'
import useSales from './useSales'

const useExpenses = buildPnl('Expenses')
const useFood = buildPnl('Food')
const useLabor = buildPnl('Labor')

export type IAiSummaryType = 'pnlSales' | 'pnlExpenses' | 'pnlFood' | 'pnlLabor'

export const hooks = [
  ['pnlSales', useSales],
  ['pnlExpenses', useExpenses],
  ['pnlFood', useFood],
  ['pnlLabor', useLabor],
] as const
