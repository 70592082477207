import { gql, useQuery } from '@apollo/client'
import * as d3 from 'd3-scale'
import { useMemo } from 'react'
import stringSimilarity from 'string-similarity'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBrand } from 'pared/utils/brand'

import { useDateFilter } from '../../../dateFilter'
import { useVariables } from '../../../variables'
import { IApiType } from '../../types'

interface IListLocationPurchaseDataNodeType {
  itemName: string
  itemCode: string
  displayParentCategoryName: string
  purchaseAmount: number
  expectedPurchaseAmount: number
  opportunityCost: number
}

interface IListLocationPurchaseDataType {
  listLocationPurchaseData: {
    nodes: IListLocationPurchaseDataNodeType[]
  }
}

const query = gql`
  query ListLocationPurchaseData(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iQueryType: String!
    $iFilter: JSON!
  ) {
    listLocationPurchaseData(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iQueryType: $iQueryType
      iFilter: $iFilter
    ) {
      nodes {
        itemName
        itemCode
        displayParentCategoryName
        purchaseAmount
        expectedPurchaseAmount
        opportunityCost
      }
    }
  }
`

export const locationPurchaseConfigs = {
  itemName: 'string',
  displayParentCategoryName: 'string',
  purchaseAmount: 'price',
  expectedPurchaseAmount: 'price',
  opportunityCost: 'price',
} as const

const useLocationPurchase = (): IApiType => {
  const metricCodes = ['purchase_amount']
  const brand = getBrand()
  const locationGroupIds = [BRAND_LOCATION_GROUP_ID[brand]]
  const { groupFilter } = useGroupFilter()
  const dateFilter = useDateFilter()
  const startDate = dateFilter.startDate
  const endDate = dateFilter.endDate
  const locationId = groupFilter?.ids?.[0]
  const { variables } = useVariables()
  const { selectedItemOrCategory, searchInput } = useMemo(
    () => ({
      selectedItemOrCategory: variables.items?.value,
      searchInput: variables.inputValue?.value as string,
    }),
    [variables],
  )
  const itemCategoryIds = parseInt(selectedItemOrCategory?.[0]?.[0]) || null
  const { data, loading } = useQuery<IListLocationPurchaseDataType>(query, {
    variables: {
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
      iQueryType: 'ITEM',
      iFilter: {
        location_group_ids: locationGroupIds,
        metrics: metricCodes,
        use_location_details: true,
        item_category_ids: [itemCategoryIds],
        bypass_row_level_security: true,
      },
    },
    skip: !startDate || !endDate || !groupFilter || !itemCategoryIds,
  })

  return {
    data: useMemo(() => {
      const itemData = data?.listLocationPurchaseData.nodes
      if (!itemData) return null

      const minOpportunityCost = Math.min(
        ...itemData.map((n) => n.opportunityCost),
      )
      const maxOpportunityCost = Math.max(
        ...itemData.map((n) => n.opportunityCost),
      )
      const x = d3.scaleLinear(
        [0, 100],
        [minOpportunityCost, maxOpportunityCost],
      )
      const final = itemData.map((n) => {
        return {
          ...n,
          parentId: 'root',
          id: n.itemCode,
          opportunityCostHeatMap: x.invert(n.opportunityCost),
        }
      })
      const finalTotal = final.reduce(
        (prev, curr) => {
          return {
            expectedPurchaseAmount:
              prev.expectedPurchaseAmount + curr?.expectedPurchaseAmount || 0,
            opportunityCost: prev.opportunityCost + curr?.opportunityCost || 0,
            purchaseAmount: prev.purchaseAmount + curr?.purchaseAmount || 0,
          }
        },
        {
          expectedPurchaseAmount: 0,
          opportunityCost: 0,
          purchaseAmount: 0,
        },
      )

      if (!searchInput)
        return [
          ...final,
          {
            parentId: 'root',
            id: 'summary',
            itemName: 'Total',
            displayParentCategoryName: '',
            expectedPurchaseAmount: finalTotal.expectedPurchaseAmount,
            opportunityCost: finalTotal.opportunityCost,
            purchaseAmount: finalTotal.purchaseAmount,
          },
        ]

      const itemFilterArray = searchInput
        .split(';')
        .map((item) => {
          return item.trim().toLowerCase()
        })
        .filter((item) => {
          return !!item
        })

      const allItemNames = final.map(({ itemName }) => itemName)
      const bestMatchItemSet = new Set<string>()

      itemFilterArray.forEach((filter) => {
        const bestMatchResults = stringSimilarity.findBestMatch(
          filter,
          allItemNames,
        )
        const bestMatchItem = bestMatchResults?.bestMatch?.target
        if (bestMatchItem) {
          bestMatchItemSet.add(bestMatchItem.toLowerCase())
        }
      })

      const filteredTableData = final.filter(({ itemName }) => {
        const lowerCaseItem = itemName.toLowerCase()
        let isMatched = false
        const filterCount = itemFilterArray.length

        for (let ii = 0; ii < filterCount; ++ii) {
          const filter = itemFilterArray[ii]

          if (
            bestMatchItemSet.has(lowerCaseItem) ||
            lowerCaseItem.search(filter) !== -1
          ) {
            isMatched = true
            break
          }
        }

        return isMatched
      })

      return filteredTableData
    }, [data, searchInput]),
    loading,
  }
}

export default useLocationPurchase
