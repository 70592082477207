import { IConfigsType } from '../../types'

const mwbPAndL: IConfigsType = {
  'mwb-only-for-qa': {
    '/:brand/corporate-p-and-l': {
      variables: ['date'],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      title: {
        type: 'title',
        title: ' ',
      },
      exray: {
        type: 'ai-summary',
        total: '',
        background: {
          colors: ['black', 'red'],
          range: [0, 1],
          value: '<%- total >= 0 ? 1 : 0 %>',
        },
        fields: [
          {
            type: 'pnlSales',
            details: [
              {
                key: 'sales',
                api: 'pnlSales',
                groupInfo: {
                  width: '150px',
                  groupBy: {
                    width: '150px',
                  },
                },
                pageSize: 20,
                columns: [
                  {
                    key: 'variance',
                    title: 'Difference vs PY PTD',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                      },
                      {
                        key: 'variancePercent',
                        title: '%',
                        align: 'center',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'pnlExpenses',
            details: [
              {
                key: 'expenses',
                api: 'pnlExpenses',
                groupInfo: {
                  width: '150px',
                  groupBy: {
                    width: '150px',
                  },
                },
                pageSize: 20,
                columns: [
                  {
                    key: 'variance',
                    title: 'Difference vs PY PTD',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                      },
                      {
                        key: 'variancePercent',
                        title: '%',
                        align: 'center',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'pnlFood',
            details: [
              {
                key: 'food',
                api: 'pnlFood',
                groupInfo: {
                  width: '150px',
                  groupBy: {
                    width: '150px',
                  },
                },
                pageSize: 20,
                columns: [
                  {
                    key: 'variance',
                    title: 'Difference vs PY PTD',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                      },
                      {
                        key: 'variancePercent',
                        title: '%',
                        align: 'center',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'pnlLabor',
            details: [
              {
                key: 'labor',
                api: 'pnlLabor',
                groupInfo: {
                  width: '150px',
                  groupBy: {
                    width: '150px',
                  },
                },
                pageSize: 20,
                columns: [
                  {
                    key: 'variance',
                    title: 'Difference vs PY PTD',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                      },
                      {
                        key: 'variancePercent',
                        title: '%',
                        align: 'center',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    '/:brand/p-and-l': {
      variables: ['date'],
      groupFilter: {
        api: 'locationFilter',
        autoComplete: true,
        width: 300,
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      title: {
        type: 'title',
        title: ' ',
      },
      exray: {
        type: 'ai-summary',
        total: '',
        background: {
          colors: ['black', 'red'],
          range: [0, 1],
          value: '<%- total >= 0 ? 1 : 0 %>',
        },
        fields: ['pnlSales', 'pnlExpenses', 'pnlFood', 'pnlLabor'],
      },
    },
  },
}

export default mwbPAndL
