import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useFirstDateOfYear } from '../dateFilter'
import { IGroupFilterDataType, useVariables } from '../variables'

export { default as format } from '../utils/format'

export type { IGroupFilterDataType }

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT' | 'SECONDS' | 'SCORE'
    value: number
  }
>

type IDataType<
  T extends string =
    | 'listLocationGroupMetricValues'
    | 'listLocationMetricValues',
> = Record<
  T,
  {
    nodes: ((T extends 'listLocationGroupMetricValues'
      ? {
          locationGroupId: number
        }
      : {
          locationId: number
        }) &
      Record<'metricData' | 'metricSummaryData', IMetricDataType>)[]
  }
>

interface IVariablesType {
  iStartDate?: string
  iEndDate?: string
  iFilter: {
    location_group_ids?: number[]
    location_ids?: number[]
    metrics?: string[]
    metric_groups?: string[]
    use_yoy?: boolean
    [key: string]: unknown
  }
  isLocationGroup: boolean
  useMetricData: boolean
  useMetricSummaryData: boolean
}

export type IMetricType =
  | string
  | { key: string; type: 'yoy' | 'prior' | 'ytd' | 'prior ytd' }

const query = gql`
  query MetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $isLocationGroup: Boolean!
    $useMetricData: Boolean!
    $useMetricSummaryData: Boolean!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $isLocationGroup) {
      nodes {
        locationGroupId
        metricData @include(if: $useMetricData)
        metricSummaryData @include(if: $useMetricSummaryData)
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $isLocationGroup) {
      nodes {
        locationId
        metricData @include(if: $useMetricData)
        metricSummaryData @include(if: $useMetricSummaryData)
      }
    }
  }
`

const defaultHandler = (variables: IVariablesType) => ({
  variables,
  skip:
    !variables.iStartDate ||
    !variables.iEndDate ||
    (!variables.iFilter.location_group_ids &&
      !variables.iFilter.location_ids) ||
    ((variables.iFilter.metrics || []).length === 0 &&
      (variables.iFilter.metric_groups || []).length === 0),
})

const useCommonMetricValuesQuery = (
  groupFilterTypes: IGroupFilterDataType['type'][],
  fields: ('metricData' | 'metricSummaryData')[],
  handler: (variables: IVariablesType) => {
    variables: IVariablesType
    skip: boolean
  } = defaultHandler,
  metrics?: string[],
  metricGroups?: string[],
  type?: 'yoy' | 'prior' | 'ytd' | 'prior ytd',
) => {
  const { variables } = useVariables()
  const dateFilterInfo = variables.date?.getInfo(type !== 'prior' ? 0 : -1)
  const ytdStartDate = useFirstDateOfYear(
    dateFilterInfo?.dateRange.endDate,
    !type || !['ytd', 'prior ytd'].includes(type) || !dateFilterInfo,
  )
  const { filter, isLocationGroup } = useMemo((): {
    filter: { location_group_ids?: number[]; location_ids?: number[] }
    isLocationGroup: boolean
  } => {
    const groupFilterInfo = variables.groupFilter?.getInfo(groupFilterTypes)

    if (groupFilterInfo) {
      if ('locationGroupIds' in groupFilterInfo)
        return {
          filter: {
            location_group_ids: groupFilterInfo.locationGroupIds,
          },
          isLocationGroup: groupFilterInfo.type === 'listLocationGroup',
        }

      if ('locationId' in groupFilterInfo)
        return {
          filter: {
            location_ids: [groupFilterInfo.locationId],
          },
          isLocationGroup: false,
        }
    }

    return { filter: {}, isLocationGroup: false }
  }, [variables])

  return useQuery<IDataType, IVariablesType>(
    query,
    handler({
      iStartDate:
        type && ['ytd', 'prior ytd'].includes(type)
          ? ytdStartDate
          : dateFilterInfo?.dateRange.startDateStr,
      iEndDate: dateFilterInfo?.dateRange.endDateStr,
      iFilter: {
        ...filter,
        ...(!type || !['yoy', 'prior ytd'].includes(type)
          ? {}
          : {
              use_yoy: true,
            }),
        metrics,
        metric_groups: metricGroups,
      },
      isLocationGroup,
      useMetricData: fields.includes('metricData'),
      useMetricSummaryData: fields.includes('metricSummaryData'),
    }),
  )
}

const useMetricValuesQuery = (options: {
  groupFilterTypes: IGroupFilterDataType['type'][]
  fields: ('metricData' | 'metricSummaryData')[]
  metrics?: IMetricType[]
  metricGroups?: IMetricType[]
  handler?: (variables: IVariablesType) => {
    variables: IVariablesType
    skip: boolean
  }
}) => {
  const { data, loading } = useCommonMetricValuesQuery(
    options.groupFilterTypes,
    options.fields,
    options.handler,
    options.metrics
      ?.map((m) => (typeof m === 'string' ? m : null))
      .filter(Boolean) as string[],
    options.metricGroups
      ?.map((m) => (typeof m === 'string' ? m : null))
      .filter(Boolean) as string[],
  )
  const { data: yoyData, loading: yoyLoading } = useCommonMetricValuesQuery(
    options.groupFilterTypes,
    options.fields,
    options.handler,
    options.metrics
      ?.map((m) => (typeof m !== 'string' && m.type === 'yoy' ? m.key : null))
      .filter(Boolean) as string[],
    options.metricGroups
      ?.map((m) => (typeof m !== 'string' && m.type === 'yoy' ? m.key : null))
      .filter(Boolean) as string[],
    'yoy',
  )
  const { data: priorData, loading: priorLoading } = useCommonMetricValuesQuery(
    options.groupFilterTypes,
    options.fields,
    options.handler,
    options.metrics
      ?.map((m) => (typeof m !== 'string' && m.type === 'prior' ? m.key : null))
      .filter(Boolean) as string[],
    options.metricGroups
      ?.map((m) => (typeof m !== 'string' && m.type === 'prior' ? m.key : null))
      .filter(Boolean) as string[],
    'prior',
  )
  const { data: ytdData, loading: ytdLoading } = useCommonMetricValuesQuery(
    options.groupFilterTypes,
    options.fields,
    options.handler,
    options.metrics
      ?.map((m) => (typeof m !== 'string' && m.type === 'ytd' ? m.key : null))
      .filter(Boolean) as string[],
    options.metricGroups
      ?.map((m) => (typeof m !== 'string' && m.type === 'ytd' ? m.key : null))
      .filter(Boolean) as string[],
    'ytd',
  )
  const { data: priorYtdData, loading: priorYtdLoading } =
    useCommonMetricValuesQuery(
      options.groupFilterTypes,
      options.fields,
      options.handler,
      options.metrics
        ?.map((m) =>
          typeof m !== 'string' && m.type === 'prior ytd' ? m.key : null,
        )
        .filter(Boolean) as string[],
      options.metricGroups
        ?.map((m) =>
          typeof m !== 'string' && m.type === 'prior ytd' ? m.key : null,
        )
        .filter(Boolean) as string[],
      'prior ytd',
    )

  return {
    data: useMemo(
      () =>
        (data?.listLocationGroupMetricValues || data?.listLocationMetricValues)
          ?.nodes,
      [data],
    ),
    yoyData: useMemo(
      () =>
        (
          yoyData?.listLocationGroupMetricValues ||
          yoyData?.listLocationMetricValues
        )?.nodes,
      [yoyData],
    ),
    priorData: useMemo(
      () =>
        (
          priorData?.listLocationGroupMetricValues ||
          priorData?.listLocationMetricValues
        )?.nodes,
      [priorData],
    ),
    ytdData: useMemo(
      () =>
        (
          ytdData?.listLocationGroupMetricValues ||
          ytdData?.listLocationMetricValues
        )?.nodes,
      [ytdData],
    ),
    priorYtdData: useMemo(
      () =>
        (
          priorYtdData?.listLocationGroupMetricValues ||
          priorYtdData?.listLocationMetricValues
        )?.nodes,
      [priorYtdData],
    ),
    loading:
      loading || yoyLoading || priorLoading || ytdLoading || priorYtdLoading,
  }
}

export default useMetricValuesQuery
