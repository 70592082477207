import { useMemo } from 'react'

import {
  useFarwestAverageWeeklySalesbyOrderMode,
  useFarwestEquivalentUnitCount,
  useFarwestTotalSalesPercentbyOrderMode,
  useFarwestTotalSalesbyOrderMode,
} from '../../table/hooks/useFarwestFlashSalesByOrderMode'

export const fwWingstopFlashSalesByOrderModeConfigs = {
  kpiName: 'string',
  farwestFlashSalesByOrderModeWeek1InPast: 'string',
  farwestFlashSalesByOrderModeWeek2InPast: 'string',
  farwestFlashSalesByOrderModeWeek3InPast: 'string',
  farwestFlashSalesByOrderModeWeek4InPast: 'string',
  farwestFlashSalesByOrderModeWeek5InPast: 'string',
  farwestFlashSalesByOrderModeWeek6InPast: 'string',
  farwestFlashSalesByOrderModeWeek7InPast: 'string',
  farwestFlashSalesByOrderModeWeek8InPast: 'string',
}

const titleData = {
  farwestFlashSalesByOrderModeWeek1InPast: '',
  farwestFlashSalesByOrderModeWeek2InPast: '',
  farwestFlashSalesByOrderModeWeek3InPast: '',
  farwestFlashSalesByOrderModeWeek4InPast: '',
  farwestFlashSalesByOrderModeWeek5InPast: '',
  farwestFlashSalesByOrderModeWeek6InPast: '',
  farwestFlashSalesByOrderModeWeek7InPast: '',
  farwestFlashSalesByOrderModeWeek8InPast: '',
}

const useFwWingstopSalesbyOrderMode = () => {
  const { data: avgSalesbyOrderModeData, loading: salesbyOrderModeLoading } =
    useFarwestAverageWeeklySalesbyOrderMode()
  const { data: salesbyOrderModeData, loading: salesbyDeliveryModeLoading } =
    useFarwestTotalSalesbyOrderMode()
  const {
    data: salesPercentbyOrderModeData,
    loading: salesPercentbyOrderModeLoading,
  } = useFarwestTotalSalesPercentbyOrderMode()
  const { data: equivalentUnitCountData, loading: equivalentUnitCountLoading } =
    useFarwestEquivalentUnitCount()

  const loading =
    salesbyOrderModeLoading ||
    salesbyDeliveryModeLoading ||
    salesPercentbyOrderModeLoading ||
    equivalentUnitCountLoading

  return useMemo(() => {
    const source = [
      { kpiName: 'Average Weekly Sales by Order Mode', ...titleData },
      ...(avgSalesbyOrderModeData?.source?.map((d) => ({
        kpiName: d.avgOrderPriceName,
        farwestFlashSalesByOrderModeWeek1InPast:
          ((d.avgOrderPriceWeek1InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek2InPast:
          ((d.avgOrderPriceWeek2InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek3InPast:
          ((d.avgOrderPriceWeek3InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek4InPast:
          ((d.avgOrderPriceWeek4InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek5InPast:
          ((d.avgOrderPriceWeek5InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek6InPast:
          ((d.avgOrderPriceWeek6InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek7InPast:
          ((d.avgOrderPriceWeek7InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek8InPast:
          ((d.avgOrderPriceWeek8InPast as number) / 100)?.toFixed(2) || '-',
      })) || []),
      {
        kpiName: 'Total',
        farwestFlashSalesByOrderModeWeek1InPast:
          (
            (avgSalesbyOrderModeData?.summary
              ?.avgOrderPriceWeek1InPast as number) / 100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek2InPast:
          (
            (avgSalesbyOrderModeData?.summary
              ?.avgOrderPriceWeek2InPast as number) / 100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek3InPast:
          (
            (avgSalesbyOrderModeData?.summary
              ?.avgOrderPriceWeek3InPast as number) / 100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek4InPast:
          (
            (avgSalesbyOrderModeData?.summary
              ?.avgOrderPriceWeek4InPast as number) / 100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek5InPast:
          (
            (avgSalesbyOrderModeData?.summary
              ?.avgOrderPriceWeek5InPast as number) / 100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek6InPast:
          (
            (avgSalesbyOrderModeData?.summary
              ?.avgOrderPriceWeek6InPast as number) / 100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek7InPast:
          (
            (avgSalesbyOrderModeData?.summary
              ?.avgOrderPriceWeek7InPast as number) / 100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek8InPast:
          (
            (avgSalesbyOrderModeData?.summary
              ?.avgOrderPriceWeek8InPast as number) / 100
          )?.toFixed(2) || '-',
      },
      { kpiName: '', ...titleData },
      { kpiName: 'Total Sales by Order Mode', ...titleData },
      ...(salesbyOrderModeData?.source?.map((d) => ({
        kpiName: d.orderPriceName,
        farwestFlashSalesByOrderModeWeek1InPast:
          ((d.orderPriceWeek1InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek2InPast:
          ((d.orderPriceWeek2InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek3InPast:
          ((d.orderPriceWeek3InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek4InPast:
          ((d.orderPriceWeek4InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek5InPast:
          ((d.orderPriceWeek5InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek6InPast:
          ((d.orderPriceWeek6InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek7InPast:
          ((d.orderPriceWeek7InPast as number) / 100)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek8InPast:
          ((d.orderPriceWeek8InPast as number) / 100)?.toFixed(2) || '-',
      })) || []),
      {
        kpiName: 'Total',
        farwestFlashSalesByOrderModeWeek1InPast:
          (
            (salesbyOrderModeData?.summary?.orderPriceWeek1InPast as number) /
            100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek2InPast:
          (
            (salesbyOrderModeData?.summary?.orderPriceWeek2InPast as number) /
            100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek3InPast:
          (
            (salesbyOrderModeData?.summary?.orderPriceWeek3InPast as number) /
            100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek4InPast:
          (
            (salesbyOrderModeData?.summary?.orderPriceWeek4InPast as number) /
            100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek5InPast:
          (
            (salesbyOrderModeData?.summary?.orderPriceWeek5InPast as number) /
            100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek6InPast:
          (
            (salesbyOrderModeData?.summary?.orderPriceWeek6InPast as number) /
            100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek7InPast:
          (
            (salesbyOrderModeData?.summary?.orderPriceWeek7InPast as number) /
            100
          )?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek8InPast:
          (
            (salesbyOrderModeData?.summary?.orderPriceWeek8InPast as number) /
            100
          )?.toFixed(2) || '-',
      },
      { kpiName: '', ...titleData },
      { kpiName: 'Total Sales Percent by Order Mode', ...titleData },
      ...(salesPercentbyOrderModeData?.source?.map((d) => ({
        kpiName: d.orderPercentName,
        farwestFlashSalesByOrderModeWeek1InPast:
          (d.orderPercentWeek1InPast as number)?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek2InPast:
          (d.orderPercentWeek2InPast as number)?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek3InPast:
          (d.orderPercentWeek3InPast as number)?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek4InPast:
          (d.orderPercentWeek4InPast as number)?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek5InPast:
          (d.orderPercentWeek5InPast as number)?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek6InPast:
          (d.orderPercentWeek6InPast as number)?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek7InPast:
          (d.orderPercentWeek7InPast as number)?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek8InPast:
          (d.orderPercentWeek8InPast as number)?.toFixed(1) || '-',
      })) || []),
      {
        kpiName: 'Total',
        farwestFlashSalesByOrderModeWeek1InPast:
          (
            salesPercentbyOrderModeData?.summary
              ?.orderPercentWeek1InPast as number
          )?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek2InPast:
          (
            salesPercentbyOrderModeData?.summary
              ?.orderPercentWeek2InPast as number
          )?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek3InPast:
          (
            salesPercentbyOrderModeData?.summary
              ?.orderPercentWeek3InPast as number
          )?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek4InPast:
          (
            salesPercentbyOrderModeData?.summary
              ?.orderPercentWeek4InPast as number
          )?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek5InPast:
          (
            salesPercentbyOrderModeData?.summary
              ?.orderPercentWeek5InPast as number
          )?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek6InPast:
          (
            salesPercentbyOrderModeData?.summary
              ?.orderPercentWeek6InPast as number
          )?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek7InPast:
          (
            salesPercentbyOrderModeData?.summary
              ?.orderPercentWeek7InPast as number
          )?.toFixed(1) || '-',
        farwestFlashSalesByOrderModeWeek8InPast:
          (
            salesPercentbyOrderModeData?.summary
              ?.orderPercentWeek8InPast as number
          )?.toFixed(1) || '-',
      },
      { kpiName: '', ...titleData },
      { kpiName: 'Equivalent Unit Count', ...titleData },
      ...(equivalentUnitCountData?.source?.map((d) => ({
        kpiName: d.equivalentUnitCountName,
        farwestFlashSalesByOrderModeWeek1InPast:
          (d.equivalentUnitCountWeek1InPast as number)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek2InPast:
          (d.equivalentUnitCountWeek2InPast as number)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek3InPast:
          (d.equivalentUnitCountWeek3InPast as number)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek4InPast:
          (d.equivalentUnitCountWeek4InPast as number)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek5InPast:
          (d.equivalentUnitCountWeek5InPast as number)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek6InPast:
          (d.equivalentUnitCountWeek6InPast as number)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek7InPast:
          (d.equivalentUnitCountWeek7InPast as number)?.toFixed(2) || '-',
        farwestFlashSalesByOrderModeWeek8InPast:
          (d.equivalentUnitCountWeek8InPast as number)?.toFixed(2) || '-',
      })) || []),
    ]

    return {
      data: {
        source,
      },
      loading,
    }
  }, [
    loading,
    avgSalesbyOrderModeData,
    salesbyOrderModeData,
    salesPercentbyOrderModeData,
    equivalentUnitCountData,
  ])
}

export default useFwWingstopSalesbyOrderMode
