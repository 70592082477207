import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const dhStorePerformance: IConfigsType = {
  dh_wb: {
    '/:brand/store_detail': {
      variables: [
        'date',
        'revenueCenterSales',
        {
          type: 'lastTimeRange',
          optionItems: [
            { timeFrame: 'days', lastDateRangeNumber: 7 },
            { timeFrame: 'weeks', lastDateRangeNumber: 4 },
            { timeFrame: 'periods', lastDateRangeNumber: 3 },
            { timeFrame: 'periods', lastDateRangeNumber: 13 },
          ],
        },
      ],
      storePerformanceTitle: {
        type: 'title',
        title: 'locationInfo',
        subTitle: 'storeSubtitle',
      },
      groupFilter: {
        api: 'locationFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
        defaultType: 'yesterday',
      },
      storePerformanceList: {
        type: 'list',
        api: 'dhWbListStorePerformanceKpis',
        fieldWidth: '250px',
        fields: [
          {
            content: {
              key: 'netSales',
            },
            bottom: 'Net Sales',
          },
          {
            content: {
              key: 'netSalesSssPercent',
            },
            bottom: 'SSS %',
          },
          {
            content: {
              key: 'checkCount',
            },
            bottom: 'Check Count',
          },
          {
            content: {
              key: 'checkAverage',
              decimal: 2,
            },
            bottom: 'Check Average',
          },
          {
            width: '125px',
            content: {
              key: 'averageWeeklySales',
            },
            bottom: 'Weekly Sales',
          },
        ],
      },
      storePerformanceTable: {
        type: 'table-v2',
        api: 'dhWbStorePerformance',
        rowBackground: `<%- '&:nth-child(even) { background-color: ${COLORS.Porcelain}; }' %>`,
        columns: [
          {
            key: 'date',
            width: '275px',
          },
          {
            key: 'netSales',
            title: 'Net Sales',
            align: 'center',
            width: '200px',
          },
          {
            key: 'laborTotalCost',
            title: 'Variable Labor',
            align: 'center',
            width: '200px',
          },
          {
            key: 'laborTotalCostPercent',
            title: 'Variable Labor %',
            align: 'center',
            width: '200px',
          },
          {
            key: 'cogsPercentage',
            title: 'Actual %',
            align: 'center',
            width: '200px',
          },
          {
            key: 'theoreticalCogsPercentage',
            title: 'Theoretical %',
            align: 'center',
            width: '200px',
          },
          {
            key: 'cogsVariancePercentage',
            title: 'Variance %',
            align: 'center',
            width: '200px',
          },
        ],
      },
      dayPartTableAndChart: {
        type: 'grid',
        children: {
          dayPart: {
            type: 'table-v2',
            api: 'locationDayPart',
            title: 'Day Part',
            rowBackground: `<%- '&:nth-child(even) { background-color: ${COLORS.Porcelain}; }' %>`,
            columns: [
              {
                key: 'name',
                title: 'Day Part',
                sortable: true,
              },
              {
                key: 'sales',
                title: 'Sales',
                unit: 'dollar',
                sortable: true,
              },
              {
                key: 'percentSales',
                title: '% Net Sales',
                sortable: true,
              },
              {
                key: 'checkCount',
                title: 'Check Count',
                sortable: true,
              },
              {
                key: 'checkAverage',
                title: 'Check Average',
                unit: 'dollar',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          dayPartPieChart: {
            type: 'pie-chart',
            api: 'locationDayPartPie',
            width: 400,
            label: 'name',
            datasets: [
              {
                key: 'netSales',
                label: 'nameWithPercentage',
                borderColor: COLORS.background,
                backgroundColor: [
                  COLORS.Plum,
                  COLORS.Mango,
                  COLORS.Basil,
                  COLORS.DarkOrchid,
                  COLORS.Acai,
                  COLORS.Pomodoro,
                ],
                hoverOffset: 4,
              },
            ],
            scaleTypes: {
              x: {
                type: 'price',
              },
            },
          },
        },
      },
      revenueCenterBreakdownTable: {
        type: 'table-v2',
        api: 'locationRevenueCenter',
        title: 'Revenue Center',
        rowBackground: `<%- '&:nth-child(even) { background-color: ${COLORS.Porcelain}; }' %>`,
        columns: [
          {
            key: 'name',
            title: 'Revenue Center',
            sortable: true,
          },
          {
            key: 'sales',
            title: 'Net Sales',
            unit: 'dollar',
            sortable: true,
          },
          {
            key: 'percentSales',
            title: '% Net Sales',
            sortable: true,
          },
          {
            key: 'checkCount',
            title: 'Check Count',
            sortable: true,
          },
          {
            key: 'checkAverage',
            title: 'Check Average',
            unit: 'dollar',
            decimal: 2,
            sortable: true,
          },
        ],
      },
      revenueCenterTrendSalesFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Sales Trend',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
          },
        },
      },
      revenueCenterTrendSales: {
        type: 'bar-chart',
        api: 'trendLocationRevenueCenterSales',
        width: 1100,
        height: 800,
        label: 'period',
        datasets: [
          {
            key: '<%- JSON(revenueCenterSales?.options) %>',
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
        },
        options: {
          scales: {
            x: {
              stacked: true,
              ticks: {
                font: {
                  size: 10,
                },
              },
            },
            y: {
              stacked: true,
            },
          },
        },
      },
      discountCompAndLabor: {
        type: 'grid',
        children: {
          discountComp: {
            type: 'table-v2',
            api: 'locationDiscountComp',
            rowBackground: `<%- '&:nth-child(even) { background-color: ${COLORS.Porcelain}; }' %>`,
            title: 'Comps',
            columns: [
              {
                key: 'name',
                title: 'Comps',
                sortable: true,
              },
              {
                key: 'amount',
                title: 'Amount',
                unit: 'dollar',
                sortable: true,
              },
              {
                key: 'percentSales',
                title: '% Net Sales',
                sortable: true,
              },
            ],
          },
          laborOverview: {
            type: 'table-v2',
            api: 'locationLaborOverview',
            rowBackground: `<%- '&:nth-child(even) { background-color: ${COLORS.Porcelain}; }' %>`,
            title: 'Labor Overview',
            columns: [
              {
                key: 'name',
                title: ' ',
                width: '150px',
              },
              {
                key: 'value',
                title: ' ',
                align: 'right',
              },
            ],
          },
        },
      },
    },
  },
}

export default dhStorePerformance
