import CircularProgress from '@material-ui/core/CircularProgress'
import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

import { useVariables } from '../variables'

export interface IPropsType {
  type: 'button'
  label: string
  loading?: string
  onClick?: string
  hide?: `<%- ${string} %>`
  margin?: string
  color?: string
  backgroundColor?: string
}

interface IButtonType {
  loading?: boolean
  onClick?: () => void
}

const SpinProgress = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  width: 100%;
  height: 100%;

  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px;
  }
`

const StyledButton = styled.div<
  Pick<IPropsType, 'margin' | 'color' | 'backgroundColor'> &
    Pick<IButtonType, 'loading'>
>`
  position: relative;
  flex: 1;
  ${({ margin }) => (!margin ? '' : `margin: ${margin};`)}
  height: 50px;
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.Expo};
  color: ${({ color }) => color || 'white'};
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  font-family: Lexend-Semibold;
  cursor: pointer;

  ${({ loading }) =>
    loading
      ? ''
      : `&:hover {
    opacity: 50%;
  }`}
`

const Button = ({ label, hide, backgroundColor, ...props }: IPropsType) => {
  const { variables, template } = useVariables()
  const { loading, onClick } = useMemo(
    (): IButtonType => ({
      loading: props.loading && _.get(variables, props.loading),
      onClick: props.onClick && _.get(variables, props.onClick),
    }),
    [props, variables],
  )

  if (hide && template(hide) !== 'false') return null

  return (
    <StyledButton
      {...props}
      backgroundColor={
        backgroundColor &&
        template(backgroundColor, { external: { colors: COLORS } })
      }
      onClick={loading ? undefined : onClick}
      loading={loading}
    >
      {!loading ? (
        label
      ) : (
        <SpinProgress>
          <CircularProgress />
        </SpinProgress>
      )}
    </StyledButton>
  )
}

export default React.memo(Button) as unknown as typeof Button
