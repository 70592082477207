import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import MaterialSelect from '@material-ui/core/Select'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import * as d3 from 'd3-hierarchy'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { useVariables } from '../variables'
import MenuItem, { IValueType } from './MenuItem'
import useValidateItems, {
  IValidateItemsOptionsType,
} from './hooks/useValidateItems'

export interface IPropsType {
  type: 'select'
  value: string
  onChange: string
  options: string | IValueType[]
  label?: string
  renderValue?: `<%- ${string} %>`
  multiple?: true
  margin?: string
}

const StyledFormControl = styled(FormControl)<{
  styleMargin?: string
}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 25px;
  ${({ styleMargin }) => (!styleMargin ? '' : `margin: ${styleMargin};`)}
`

const Label = styled.div`
  display: inline-block;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const StyledSelect = styled(MaterialSelect)`
  font-family: Lexend-Regular;
  min-width: 150px;

  &.MuiOutlinedInput-root {
    border-radius: 0;
  }
`

const Loading = styled(TextField)`
  .MuiInputBase-root {
    font-family: Lexend-Regular;
    border-radius: 0;
    cursor: default;

    input {
      cursor: default;
    }
  }
`

const Select = ({
  label,
  multiple,
  renderValue = '<%- values[0].map(v => v.displayName).join(" / ") %>',
  margin,
  ...props
}: IPropsType) => {
  const [open, setOpen] = useState(false)
  const { variables, template } = useVariables()
  const value = useMemo(
    (): IValidateItemsOptionsType['value'] => _.get(variables, props.value),
    [props, variables],
  )
  const { onChange, options } = useMemo(
    () => ({
      onChange: _.get(
        variables,
        props.onChange,
      ) as IValidateItemsOptionsType['onChange'],
      options: d3.stratify<IValueType>()([
        { id: 'root' } as IValueType,
        ...(typeof props.options === 'string'
          ? _.get(variables, props.options)
          : props.options),
      ]),
    }),
    [props, variables],
  )
  const { value: menuValue, onChange: menuOnChange } = useValidateItems({
    value,
    onChange,
    options,
    multiple,
  })

  return (
    <StyledFormControl styleMargin={margin} variant="outlined" margin="dense">
      {!label ? null : <Label>{template(label)}</Label>}

      {!options.children ? (
        <Loading
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ),
            readOnly: true,
          }}
          placeholder="Loading..."
          size="small"
          variant="outlined"
        />
      ) : (
        <StyledSelect
          value={value.join('/')}
          renderValue={() =>
            _.template(renderValue)({
              values: menuValue,
            }).replace(/&amp;/g, '&')
          }
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          variant="outlined"
        >
          {options.children.map((option) => (
            <MenuItem
              key={option.data.id}
              onChange={menuOnChange}
              onClose={() => setOpen(false)}
              option={option}
              selectedIds={value.filter((v) => v[0] === option.data.id)}
              multiple={multiple || false}
            />
          ))}
        </StyledSelect>
      )}
    </StyledFormControl>
  )
}

export default React.memo(Select) as unknown as typeof Select
